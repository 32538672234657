//@import '~@angular/material/theming';
@use '@angular/material' as mat;

@include mat.core();

:root {
  --mdc-typography-font-family: Arial, sans-serif;
}

$custom-palette: (
  50 : #3898EC,
  100 : #3898EC,
  200 : #3898EC,
  300 : #3898EC,
  400 : #3898EC,
  500 : #3898EC,
  600 : #3898EC,
  700 : #3898EC,
  800 : #3898EC,
  900 : #3898EC,
  contrast: (
    50 : #ffffff,
    100 : #ffffff,
    200 : #ffffff,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
  )
);

$custom-primary: mat.define-palette($custom-palette);

$theme: mat.define-light-theme($custom-primary, $custom-primary);

@include mat.all-component-themes($theme);


@mixin mobile-lg {
  @media screen and (max-width: 970px) {
    @content;
  }
}


input::-webkit-input-placeholder, input::placeholder {
  color: #333333!important;
}

mat-form-field {
  width: 100%;
}

.mdc-text-field {
  .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
    font-size: 14px;
    font-style: italic;
    color: #333;
  }
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  min-height: 48px;
  padding: 10px 20px;
  background-color: rgba(70, 71, 71, 0.04);
  align-items: center;
  border-radius: 0;
}

.mat-mdc-form-field {
  position: relative;
}

.mat-mdc-form-field-infix {
  min-height: 0;
}
.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 0;
  padding-bottom: 0;
}

.mdc-line-ripple {
  display: none;
}

.mat-mdc-form-field-focus-overlay {
  display: none;
}

.mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
  position: absolute;
  bottom: -22px;
  left: 0;
  z-index: 0;
}

.mdc-button.mat-mdc-raised-button {
  box-shadow: none;
  border-radius: 0;
}

.mdc-button.mat-mdc-raised-button:not(:disabled) {
  color: #ffffff;
}

.mdc-button.mat-mdc-raised-button, .mat-mdc-button.mat-mdc-button-base {
  height: 48px;
}

.mdc-button {
  font-size: 16px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.row-start {
  align-items: flex-start;
}

.row-wrap {
  flex-wrap: wrap;
}

.flex-start {
  justify-content: flex-start;
}

.text-bold {
  font-weight: 700;
}

.title {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.0125em;
}

.subtitle {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.0125em;
}

.overlay-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.5);
}

h2 {
  font-size: 17px;
  font-weight: 700;
  letter-spacing: 0.0125em;
}

.article-link {
  max-width: 200px;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.articles-row {
  justify-content: flex-start;
  gap: 4px;
  flex-wrap: wrap;
}

mat-expansion-panel-header.mat-expansion-panel-header,
mat-expansion-panel-header.mat-expansion-panel-header.mat-expanded {
  @include mobile-lg {
    height: auto;
    padding: 8px 16px;
  }
}