/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Arial, sans-serif; }

* {
  box-sizing: border-box;
}

app-root {
  height: auto;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d1d1d1;
  background-image: url(assets/images/bg.svg);
  background-position: 50%;
  background-size: 96px 96px;

  @media screen and (max-width: 970px) {
    background-image: none;
  }
}
